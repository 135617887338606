import Vue from "vue";
import VueRouter from "vue-router";
import router from './router'
import store from './store'
import App from "./App.vue"
import './registerServiceWorker'
import Buefy from "buefy";
import VueHorizontalList from "vue-horizontal-list";
import "buefy/dist/buefy.css";

import { firestorePlugin } from 'vuefire';
import { Auth } from "@/firebase/auth";
import VueBottomNavigation from "bottom-navigation-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueAddtohomescreen from "@owliehq/vue-addtohomescreen";
Vue.use(VueAddtohomescreen);




Vue.config.productionTip = false;
Vue.use(firestorePlugin);

Vue.use(VueBottomNavigation);


Vue.use(Buefy);
Vue.use(VueHorizontalList);
Vue.use(VueRouter);

Sentry.init({
  Vue,
  //dsn: "https://20a953afbe3342058a21403d8641fadb@o395072.ingest.sentry.io/6137447",
  dsn: "https://e3ba6e643fc346a79379be2e0c2bb6f6@o395072.ingest.sentry.io/6137457",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["run.getinshoot.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Auth.onAuthStateChanged(user => {
  store.commit('setUser', user);
  new Vue({
    router,
    store,
    el: "#app",
    render: h => h(App)
  })
});