<template >
  <Recorder :type="type" :code="code" :eventId="id" />
</template>

<script>
import Recorder from "./components/recorder.vue";

export default {
  name: "RecorderWrapper",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { Recorder },
  data: function () {
    return {
      type: "",
      code: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.code = this.$route.query.code;
  },
};
</script>

<style src="./components/index.css">
</style>