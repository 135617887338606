<template>
  <section
    :style="{
      backgroundImage: ` url(${imgBG})`,
    }"
    style="background-color: #161032; height: 100vh"
  >
    <section>
      <div style="margin: 0 auto" class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-2 is-vertical"></div>
            <div class="tile is-parent">
              <article
                style="background-color: #392774; color: white"
                class="tile is-child notification has-text-centered"
              >
                <span>
                  <img
                    @click="$router.go(-1)"
                    style="
                      position: absolute;
                      width: 20px;
                      top: 10px;
                      left: 10px;
                    "
                    src="../public/img/back-camera.png"
                  />
                </span>
                <br />
                <p
                  v-if="event && event.allowContribType != 'photo'"
                  class="title is-6"
                >
                  Durée maxi vidéo
                  {{ event.mediaduration }} secondes
                </p>
                <p v-if="event && allowedFilesNb" class="title is-6">
                  Vous pouvez importer
                  {{ allowedFilesNb }} fichiers
                </p>

                <b-field v-if="!uploading">
                  <b-upload
                    v-model="tempFile"
                    native
                    :accept="allowedFilesExt"
                    drag-drop
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p class="title is-4">
                          formats acceptés :
                          {{
                            !event
                              ? ""
                              : !event.allowContribType ||
                                event.allowContribType == "all"
                              ? "mp4, mov, avi, jpg, jpeg, png"
                              : event.allowContribType == "video"
                              ? "mp4, mov, avi"
                              : "jpg, jpeg, png"
                          }}
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div v-else>
                  <b-progress
                    type="is-info"
                    :max="100"
                    :value="progress"
                    show-value
                    size="is-medium"
                  >
                  </b-progress>
                </div>

                <div class="tags" v-if="tempFile && !uploading">
                  <span class="tag is-primary">
                    {{ tempFile.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile()"
                    ></button>
                  </span>
                </div>

                <p v-if="!uploading" class="recorder-footer-item">
                  <span>
                    <b-button
                      style="
                        background-color: #17bebb;
                        color: white;
                        font-weight: bold;
                      "
                      size="is-default"
                      :disabled="!file"
                      :loading="tempFile && !file"
                      @click="checkRights()"
                      >valider ❤️</b-button
                    >
                  </span>
                </p>

                <b-modal v-model="isModalLogged" :width="640" scroll="keep">
                  <RightsCession
                    :host="event.host"
                    :eventName="event.name"
                    :eventId="id"
                    :code="code"
                    v-on:validated="checkRights()"
                  />
                </b-modal>
              </article>
            </div>

            <div class="tile is-parent is-2 is-vertical"></div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { DB } from "@/firebase/db";
import "firebase/firestore";
import RightsCession from "./components/rightsCession.vue";
import imgBG from "../public/img/background_pictos.svg";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

export default {
  name: "Uploader",
  props: ["id"],
  components: { RightsCession },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  data() {
    return {
      imgBG,
      user: {},
      isModalLogged: false,
      isModalNotLogged: false,
      readMore: false,
      file: null,
      tempFile: null,
      code: "",
      event: {},
      allowedFilesExt: "",
      allowedFilesNb: null,
      uploading: false,
      progress: 0,
    };
  },
  async beforeMount() {
    this.code = this.$route.query.code;
    this.event = (await DB.collection("events").doc(this.id).get()).data();
    this.allowedFilesExt =
      !this.event?.allowContribType || this.event?.allowContribType == "all"
        ? ".mp4,.mov,.avi,.jpg,.jpeg,.png,.MP4,.MOV,.AVI,.JPG,.JPEG,.PNG"
        : this.event?.allowContribType == "video"
        ? ".mp4,.mov,.avi,.MP4,.MOV,.AVI"
        : ".jpg,.jpeg,.png,.JPG,.JPEG,.PNG";
    if (this.$store.getters.activeUser) {
      let userContribs = (
        await DB.collection("contributions")
          .where("eventid", "==", this.id)
          .where("userid", "==", this.$store.getters.activeUser.uid)
          .get()
      ).docs;
      this.allowedFilesNb = this.event.mediabyguest - userContribs.length;
    }
  },
  mounted() {},
  watch: {
    tempFile: {
      handler() {
        if (this.tempFile) {
          this.checkFileExt();
          this.checkDuration();
        }
      },
      deep: true,
    },
  },

  methods: {
    deleteDropFile() {
      this.tempFile = null;
      this.file = null;
    },
    checkFileExt() {
      if (
        !this.allowedFilesExt
          .split(",")
          .includes("." + this.tempFile.name.split(".").pop())
      ) {
        this.tempFile = null;
        this.$buefy.toast.open({
          message: "Le format du fichier n'est pas pris en charge.",
          type: "is-danger",
          position: "is-top",
        });
      }
    },
    checkDuration() {
      if (this.tempFile.type.startsWith("video")) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var videoElement = document.createElement("video");
          videoElement.preload = "metadata";
          videoElement.type = "video/mp4";
          videoElement.addEventListener("loadedmetadata", () => {
            if (videoElement.duration > this.event.mediaduration) {
              this.tempFile = null;
              this.$buefy.toast.open({
                message: "La durée de la vidéo est trop longue",
                type: "is-danger",
                position: "is-top",
              });
            } else {
              this.file = this.tempFile;
            }
          });
          videoElement.addEventListener("error", () => {
            const readChunk = (chunkSize, offset) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                  if (event.target.error) {
                    reject(event.target.error);
                  }
                  resolve(new Uint8Array(event.target.result));
                };
                reader.readAsArrayBuffer(
                  this.tempFile.slice(offset, offset + chunkSize)
                );
              });
            const getSize = () => this.tempFile.size;
            try {
              window.MediaInfo(
                { format: "JSON", locateFile: (path, prefix) => prefix + path, },
                (mediainfo) => {
                  mediainfo.analyzeData(getSize, readChunk).then((result) => {
                    let data = JSON.parse(result);
                    let duration = data.media.track.find(
                      (t) => t["@type"] == "General"
                    ).Duration;
                    console.log("Duration from mediainfo", duration)
                    if (duration > this.event.mediaduration) {
                      this.tempFile = null;
                      this.$buefy.toast.open({
                        message: "La durée de la vidéo est trop longue",
                        type: "is-danger",
                        position: "is-top",
                      });
                    } else {
                      this.file = this.tempFile;
                    }
                  });
                },
                (error) => {
                  console.error(error);
                  this.tempFile = null;
                }
              );
            } catch (e) {
              this.tempFile = null;
            }
          });
          videoElement.src = e.target.result;
        };
        reader.readAsDataURL(this.tempFile);
      } else {
        this.file = this.tempFile;
      }
    },
    async checkRights() {
      if (this.$store.getters.activeUser) {
        this.isModalNotLogged = false;
        let invitation = (
          await DB.collection("invitations")
            .where("eventid", "==", this.id)
            .where("userid", "==", this.$store.getters.activeUser.uid)
            .get()
        ).docs[0];
        if (invitation && invitation.data().joined) {
          this.isModalLogged = false;
          this.uploadContrib();
        } else {
          if (this.eventName == "") {
            DB.collection("events")
              .doc(this.id)
              .get()
              .then((doc) => {
                this.eventName = doc.data().name;
                this.eventHost = doc.data().host;
                this.isModalLogged = true;
              });
          } else {
            this.isModalLogged = true;
          }
        }
      } else {
        this.anonymousLogin();
      }
    },
    async anonymousLogin() {
      await this.$store.dispatch("anonymousLogin");
      this.checkRights();
    },
    async uploadContrib() {
      let file = this.file;
      let type = "video";
      if (
        [".jpg", ".jpeg", ".png", ".JPG", ".JPEG", ".PNG"].includes(
          "." + file.name.split(".").pop()
        )
      ) {
        type = "photo";
      }
      let storagePath =
        "participations/" +
        this.id +
        (type == "video" ? "/videos/" : "/photos/") +
        this.$store.getters.activeUser.uid +
        "/" +
        file.name;

      let datas = {
        userid: this.$store.getters.activeUser.uid,
        eventid: this.id,
        type: type,
        format: type == "video" ? "mp4" : "png",
        file: file.name,
        from: this.$store.getters.activeUser.email,
        length: 0,
        created: new Date(),
        modified: new Date(),
        fileURL: "",
        size: 0,
      };

      const storage = getStorage();
      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          switch (snapshot.state) {
            case "running":
              this.uploading = true;
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          try {
            this.progress = 100;
            datas.fileURL = await getDownloadURL(uploadTask.snapshot.ref);
            await DB.collection("contributions").add(datas);
            this.$router.push(
              "/success/" + this.id + "?type=" + type + "&code=" + this.code
            );
          } catch (e) {
            this.$buefy.toast.open({
              message: "Erreur inatendue",
              type: "is-danger",
              position: "is-top",
            });
          }
        }
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.timerUpdater);
  },
};
</script>

<style src="./components/index.css">
</style>