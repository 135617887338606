<template>
  <section class="box" style="box-shadow: none; background-color: transparent">
    <vue-horizontal-list :items="events" :options="options">
      <template v-slot:default="{ item }">
        <div @click="joinProject(item)" class="card" style="cursor: pointer">
          <div class="card-image">
            <figure class="image is-1by1">
              <img :src="item.thumbnailURL" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">{{ item.name }}</p>
                <p class="subtitle is-6">
                  {{ item.remainingTime }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vue-horizontal-list>
    <b-modal :active.sync="showJoinModal">
      <Join :event="selectedEvent"></Join>
    </b-modal>
  </section>
</template>

<script>
import VueHorizontalList from "vue-horizontal-list";
import Join from "./join.vue";
import { DB } from "@/firebase/db";
import "firebase/firestore";

export default {
  name: "Joined",
  components: { VueHorizontalList, Join },
  data() {
    return {
      events: [],
      timerUpdater: null,
      showJoinModal: false,
      selectedEvent: null,
      options: {
        responsive: [
          { end: 96, size: 1 },
          { start: 96, end: 550, size: 2 },
          { start: 550, end: 1000, size: 4 },
          { start: 1000, end: 1200, size: 6 },
          { start: 1200, end: 1600, size: 7 },
          { start: 1600, size: 10 },
        ],
        position: {
          start: 1,
        },
      },
    };
  },
  mounted() {
    DB.collection("events")

      .where("stopped", "==", false)
      .where("archived", "==", false)
      .orderBy("created", "desc")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (event) => {
          if (this.$store.getters.activeUser) {
            let invitation = (
              await DB.collection("invitations")
                .where("eventid", "==", event.id)
                .where("userid", "==", this.$store.getters.activeUser.uid)
                .get()
            ).docs[0];
            if (invitation && invitation.data().joined) {
              this.events.push({ ...event.data(), ...{ id: event.id } });
            }
          }
        });
      });
    this.timerUpdater = setInterval(() => {
      let e = [];
      this.events.forEach((event) => {
        if (event.eventduration && event.startedAt) {
          try {
            event.remainingTime = this.formatRemainingTime(
              new Date(event.startedAt.toDate().getTime() + event.eventduration)
            );
          } catch (e) {
            event.remainingTime = this.formatRemainingTime(
              new Date(event.startedAt + event.eventduration)
            );
          }
        }
        e.push(event);
      });
      this.events = e;
    }, 1000);
  },
  methods: {
    async joinProject(event) {
      if (this.$store.getters.activeUser) {
        let invitation = (
          await DB.collection("invitations")
            .where("eventid", "==", event.id)
            .where("userid", "==", this.$store.getters.activeUser.uid)
            .get()
        ).docs[0];
        if (!invitation || !invitation.data().joined) {
          this.selectedEvent = event;
          this.showJoinModal = true;
        } else {
          this.$router.push("/event/" + event.id);
        }
      } else {
        this.$router.push("/login");
      }
    },
    formatRemainingTime(endDate) {
      let remainingTime = endDate.getTime() - new Date().getTime();
      let remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      let remainingHours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let remainingMinutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      let remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      return `${remainingDays}j ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timerUpdater);
  },
};
</script>

<style src="./index.css">
</style>