import Firebase from 'firebase/compat/app'
import credentials from './credentials'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

export const App = Firebase.initializeApp(credentials.config);
App.firestore().settings({ experimentalForceLongPolling: true, merge: true });

export default {

}