<template>
  <div>
    <div class="invoice-box">
      <br />
      <h2 style="text-align: center">Autorisation de droit à l'image</h2>
      <br /><br />
      <p>
        Je soussigné {{ guestSurName }} {{ guestLastName }},
        {{ guestEmail }} né(e) le {{ birthDate }} à {{ postalCode }}, agissant
        en mon nom personnel, <br /><br />
        Autorise {{ hostName }}, à reproduire et exploiter mon image fixée dans
        le cadre de l’enregistrement de mon témoignage vidéo qui a lieu le
        {{ recordDay }} à {{ recordHour }}. <br /><br />
        − la fixation de mon image et/ou de ma voix dans le cadre des vidéos
        prises au cours du témoignage précité et la reproduction de mon image
        ainsi fixée par tous procédés techniques connus ou inconnus à ce jour
        (graphique, photographique, numérique, etc.), sur tous supports que le
        cessionnaire serait amenée à utiliser ou créer dans le but de sa
        promotion, etc.) sur tous formats, pour un nombre illimité
        d’utilisations, en intégralité ou en partie, ensemble ou séparément, aux
        fins de communication.
        <br /><br />
        − la communication au public de mon image et/ou de ma voix ainsi fixée
        et reproduite, en tout ou en partie, au travers de tout moyen de
        diffusion, connu ou inconnu à ce jour, et notamment communication par
        voie électronique (site internet, Extranet, Intranet, etc., quel qu’en
        soit le format et quel qu’en soit le vecteur et l’appareil de réception,
        ainsi que par mise à la disposition du public quel que soit le procédé
        analogique ou numérique (et notamment downloading, uploading, etc.) ou
        le mode de transmission audiovisuel ou téléphonique mobile ou fixe
        utilisé. <br /><br />La présente cession de droits est consentie :
        <br />- Pour une exploitation dans le monde entier <br />- Pour une
        durée limitée à 15 ans à compter de la date de signature du présent
        contrat. <br />- De manière irrévocable durant la durée de ce contrat<br /><br />
        Les éventuels commentaires et/ou légendes accompagnant le cas échéant la
        reproduction ou la représentation de l’image et/ou de la voix totale ou
        partielle du cédant dans les conditions visées ci-dessus ne porteront
        pas atteinte à la réputation ou à la vie privée du cédant.
        <br /><br />
        La présente autorisation d’exploitation de mon droit à l’image est
        consentie à titre gratuit et ne pourra faire l’objet d’une demande de
        rémunération a posteriori.
        <br /><br />
        Fait le {{ recordDay }} <br /><br />
        {{ guestSurName }} {{ guestLastName }}
      </p>
    </div>
  </div>
</template>


<script>
export default {
  name: "Terms",
  props: {
    guestSurName: {
      type: String,
      required: true,
    },
    guestLastName: {
      type: String,
      required: true,
    },
    guestEmail: {
      type: String,
      required: true,
    },
    birthDate: {
      type: String,
      required: true,
    },
    postalCode: {
      type: String,
      required: true,
    },
    hostName: {
      type: String,
      required: true,
    },
    recordDay: {
      type: String,
      required: true,
    },
    recordHour: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>


<style src="./index.css">
</style>
