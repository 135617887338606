import Vue from 'vue'
import Router from 'vue-router'
import Home from './Home.vue'
import Event from "./Event.vue";
import RecorderWrapper from "./RecorderWrapper.vue";
import Uploader from "./Uploader.vue";
import Success from "./success.vue";
import Profile from "./Profile.vue";


import V0 from "../src/layouts/v0.vue";

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: V0,
            children: [
                {
                    path: '/',
                    component: Home,
                },
                {
                    path: '/event/:id',
                    component: Event,
                    props: true
                },
                {
                    path: '/recorder/:id',
                    component: RecorderWrapper,
                    props: true
                },
                {
                    path: '/uploader/:id',
                    component: Uploader,
                    props: true
                },
                {
                    path: '/success/:id',
                    component: Success,
                    props: true
                },

                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile
                },

            ],

        },
    ]
})