<template>
  <section
    :style="{
      backgroundImage: ` url(${imgBG})`,
    }"
    style="background-color: #ffffff; height: 100vh"
  >
    <b-modal
      :active.sync="unlogged"
      :on-cancel="loginCancelled"
      :width="640"
      scroll="keep"
      ><div
        class="section"
        style="
          background-color: white !important;
          padding: 20px;
          border-radius: 15px;
        "
      >
        <div class="media">
          <div class="media-left">
            <figure class="image is-128x128">
              <img src="../public/img/connexion-inscription.png" />
            </figure>
          </div>
          <div class="media-content">
            <div class="media-content">
              <br />
              <h2>Inscrivez-vous gratuitement</h2>
              <p>
                Pour valider plus facilement vos futures participations,
                inscrivez-vous gratuitement sur Getinshoot.
              </p>
            </div>
          </div>
        </div>

        <br />
        <Login :returnType="'logged'" v-on:logged="$router.go()" />
      </div>
    </b-modal>
    <div v-if="!editInformation">
      <section class="tile is-full" style="background-color: !important">
        <div class="container">
          <br />
          <br />
          <article class="media">
            <div class="media-content has-text-centered">
              <div class="has-text-centered">
                <figure class="image is-128x128 is-inline-block">
                  <img
                    v-if="user.thumbnailURL"
                    class="is-rounded"
                    :src="user.thumbnailURL"
                    style="
                      width: 100%;
                      object-fit: cover !important;
                      height: 100%;
                    "
                  />
                  <img
                    v-else
                    class="is-rounded"
                    src="../public/img/account.png"
                    style="
                      width: 100%;
                      object-fit: cover !important;
                      height: 100%;
                    "
                  />
                </figure>
              </div>

              <div class="content">
                <p>
                  <strong>{{ user.name }}</strong>

                  <br />
                  {{ user.email }}
                </p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item">
                  <span>
                    <small class="subtitle is-6"
                      ><strong>challenges joined</strong><br
                    /></small>
                    <h2>{{ joinedEvents }}</h2>
                  </span>
                </p>
                <p class="card-footer-item">
                  <span
                    ><small class="subtitle is-6"
                      ><strong>shoots</strong><br
                    /></small>
                    <h2>{{ shootEvents }}</h2>
                  </span>
                </p>
              </footer>
              <br />
              <b-button
                @click="informationSwitchMode()"
                size="is-small"
                type="is-warning"
                icon-left="account-edit"
              >
                modifier </b-button
              >&nbsp;
              <b-button
                @click="logout"
                size="is-small"
                type="is-primary is-light"
                icon-left="logout"
              >
                déconnexion
              </b-button>
            </div>
          </article>

          <br />
        </div>
      </section>
    </div>

    <div v-else class="box is-10" style="box-shadow: none; padding-left: 35px">
      <br />

      <article class="media">
        <div class="media-left has-text-centered">
          <figure class="image is-128x128">
            <img
              v-if="user.thumbnailURL"
              @click="$refs.file.click()"
              style="
                cursor: pointer;
                width: 100%;
                object-fit: cover !important;
                height: 100%;
              "
              class="is-rounded"
              :src="user.thumbnailURL"
              alt="Image"
            />
            <img
              v-else
              @click="$refs.file.click()"
              style="
                cursor: pointer;
                width: 100%;
                object-fit: cover !important;
                height: 100%;
              "
              class="is-rounded"
              src="../public/img/account.png"
              alt="Image"
            />
            <div class="tile is-child is-centered has-text-centered">
              <input
                type="file"
                accept="image/*"
                ref="file"
                style="display: none"
                @change="uploadNewCover()"
              />
            </div>
          </figure>
          <br />
        </div>

        <div class="media-content">
          <div class="content">
            <b-field label="pseudo" :label-position="labelPosition">
              <b-input v-model="user.name" value="user.name"></b-input>
            </b-field>
            <b-field label="email" :label-position="labelPosition">
              <b-input v-model="user.email" value="user.email"></b-input>
            </b-field>

            <b-field>
              <b-radio-button
                v-model="user.lang"
                native-value="fr"
                size="is-small"
                type="is-info"
              >
                <span>FR</span>
              </b-radio-button>

              <b-radio-button
                v-model="user.lang"
                native-value="en"
                size="is-small"
                type="is-danger"
              >
                <span>EN</span>
              </b-radio-button>
            </b-field>
          </div>
          <hr />
        </div>
      </article>
      <b-field label="centre d'intérêts" :label-position="labelPosition">
        <b-taginput v-model="user.areaOfInterest"> </b-taginput>
      </b-field>
      <br />
      <b-field label="date de naissance" :label-position="labelPosition">
        <b-datepicker
          :show-week-number="false"
          :locale="user.lang"
          placeholder="selectionner..."
          icon="calendar-today"
          trap-focus
          :first-day-of-week="1"
          v-model="user.dateOfBirth"
        >
        </b-datepicker>
      </b-field>
      <b-field label="">
        <b-radio v-model="user.type" native-value="man">
          <small>homme</small></b-radio
        >
        <b-radio v-model="user.type" native-value="woman">
          <small>femme</small></b-radio
        >
        <b-radio v-model="user.type" native-value="non-binary">
          <small>non binaire</small></b-radio
        >
      </b-field>
      <br />
      <b-field label="ville" :label-position="labelPosition">
        <b-input
          v-model="user.placeOfResidence"
          value="user.placeOfResidence"
        ></b-input>
      </b-field>
      <b-field label="prénom" :label-position="labelPosition">
        <b-input v-model="user.firstName" value="user.firstName"></b-input>
      </b-field>
      <b-field label="nom" :label-position="labelPosition">
        <b-input v-model="user.lastName" value="user.lastName"></b-input>
      </b-field>
      <b-button
        @click="save()"
        style="background-color: #17bebb; color: white; font-weight: bold"
        >sauvegarder</b-button
      >
      <hr />
      <b-field>
        <b-checkbox disabled :value="true"
          ><small
            >J'ai lu et j'ai accepté les
            <a
              href="
                https://www.getinshoot.com/en/privacy/ 
              "
              target="_blank"
              >CGU de Getinshoot</a
            ></small
          ></b-checkbox
        >
      </b-field>
      <b-field>
        <b-checkbox :value="false"
          ><small>supprimer mon compte getinshoot</small></b-checkbox
        >
      </b-field>

      <br />
      <br />
      <br />
    </div>
  </section>
</template>

<script>
import { DB } from "@/firebase/db";
import "firebase/firestore";
import { Auth } from "@/firebase/auth";
import imgBG from "../public/img/background_pictos.svg";
import Login from "../src/views/user/Login.vue";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default {
  name: "Profile",

  components: {
    Login,
  },
  data() {
    return {
      imgBG,
      user: {},
      labelPosition: "on-border",
      editInformation: false,
      joinedEvents: "-",
      shootEvents: "-",
      unlogged: false,
    };
  },
  created() {
    this.unlogged = this.$store.getters.activeUser == null;
  },

  methods: {
    logout() {
      Auth.signOut().then(() => {
        this.$router.push("/");
      });
    },
    loginCancelled() {
      this.$router.push("/");
    },
    informationSwitchMode() {
      this.editInformation = !this.editInformation;
    },
    async save() {
      let data = {
        name: this.user.name,
        username: this.user.name,
        email: this.user.email,
        lang: this.user.lang ? this.user.lang : "fr",
      };
      if (this.user.dateOfBirth) {
        data["dateOfBirth"] = this.user.dateOfBirth;
      }
      if (this.user.type) {
        data["type"] = this.user.type;
      }
      if (this.user.placeOfResidence) {
        data["placeOfResidence"] = this.user.placeOfResidence;
      }
      if (this.user.firstName) {
        data["firstName"] = this.user.firstName;
      }
      if (this.user.lastName) {
        data["lastName"] = this.user.lastName;
      }
      if (this.user.areaOfInterest) {
        data["areaOfInterest"] = this.user.areaOfInterest;
      }
      console.log(this.$store.getters.activeUser.uid);
      console.log(data);
      await DB.collection("users")
        .doc(this.$store.getters.activeUser.uid)
        .update(data);
      this.editInformation = false;
    },
    async uploadNewCover() {
      let filename = this.$refs.file.files[0].name;
      filename = filename.replace(/[^a-z0-9]/gi, "").toLowerCase();
      let path =
        "avatars/" + this.$store.getters.activeUser.uid + "/" + filename;
      let metadata = { contentType: "image/jpeg" };

      const storage = getStorage();
      const storageRef = ref(storage, path);

      const uploadTask = uploadBytesResumable(
        storageRef,
        this.$refs.file.files[0],
        metadata
      );

      this.$buefy.toast.open({
        message: "UploadingFile",
        position: "is-top",
      });

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          console.error(error);
        },
        async () => {
          try {
            let url = await getDownloadURL(uploadTask.snapshot.ref);
            this.user.thumbnailURL = url;
            this.$buefy.toast.open({
              message: "Import terminé",
              type: "is-success",
              position: "is-top",
            });
          } catch (e) {
            this.$buefy.toast.open({
              message: "Erreur inatendue",
              type: "is-danger",
              position: "is-top",
            });
          }
        }
      );
    },
  },

  mounted() {
    var user = this.$store.getters.activeUser;
    if (user) {
      DB.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          this.user = doc.data();
          if (
            !this.user.areaOfInterest ||
            this.user.areaOfInterest.length == 0
          ) {
            this.user.areaOfInterest = [
              "aventure",
              "sport",
              "famille",
              "culture",
              "rencontre",
              "jeux vidéos",
            ];
          }
        });
      DB.collection("invitations")
        .where("userid", "==", user.uid)
        .where("joined", "==", true)
        .get()
        .then((querySnapshot) => {
          this.joinedEvents = querySnapshot.size;
        });
      DB.collection("contributions")
        .where("userid", "==", user.uid)
        .get()
        .then((querySnapshot) => {
          this.shootEvents = querySnapshot.size;
        });
    }
  },
};
</script>

<style src="./components/index.css">
</style>