<template>
  <section
    :style="{
      backgroundImage: ` url(${imgBG})`,
    }"
    style="background-color: #161032; height: 100vh"
  >
    <section>
      <div style="margin: 0 auto" class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-2 is-vertical"></div>
            <div class="tile is-parent">
              <article
                style="min-height: 300px"
                :style="{
                  backgroundImage: !event
                    ? ''
                    : `linear-gradient(rgba(0, 0, 0, 0)0%, rgba(22,16,50,1)70%), url(${event.thumbnailURL})`,
                }"
                class="tile is-child notification backgroundevent"
              >
                <div>
                  <div class="media">
                    <div
                      class="media-left"
                      style="margin-right: 0.5rem !important"
                    >
                      <figure class="image is-inline-block">
                        <img
                          v-if="event.hostAvatarUrl"
                          class="is-rounded"
                          style="
                            object-fit: cover !important;
                            height: 30px;
                            width: 30px;
                          "
                          alt="host avatar url"
                          :src="event.hostAvatarUrl"
                        />
                        <img
                          v-else
                          class="is-rounded"
                          style="
                            object-fit: cover !important;
                            height: 30px;
                            width: 30px;
                          "
                          alt="host avatar url"
                          src="../public/img/account.png"
                        />
                      </figure>
                    </div>
                    <div class="media-content" style="padding-top: 8px">
                      <p
                        class="title is-4"
                        style="color: white; text-shadow: 1px 1px 3px #444"
                      >
                        {{ event.host }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style="
                    position: absolute;

                    bottom: 20px;
                    max-height: 220px !important;
                    overflow-y: auto;
                  "
                >
                  <h2
                    style="
                      color: white !important;
                      text-shadow: 1px 1px 3px #444;
                    "
                    class="title"
                  >
                    {{ event.name }}
                  </h2>

                  <p v-if="!readMore" style="color: white !important">
                    {{ event.description | truncate(100, "...")
                    }}<span v-if="readMore"></span>
                  </p>

                  <p style="color: white !important" v-show="readMore">
                    {{ event.description }}
                  </p>
                  <br />
                </div>
                <span
                  style="position: absolute; bottom: 0px"
                  class="has-text-centered"
                  ><b-button
                    size="is-small"
                    style="
                      background-color: #161032;
                      border: 1px solid #392774 !important;
                      color: white;
                      border-radius: 5px !important;
                    "
                    @click="readMore = !readMore"
                  >
                    <span v-if="readMore">Voir moins</span>
                    <span v-else>Voir plus</span>
                  </b-button></span
                >
              </article>
            </div>
            <div
              v-if="event.stopped == true"
              class="tile is-parent is-vertical"
            >
              <article
                class="tile is-child notification has-text-centered"
                style="background-color: #1b1051; color: white !important"
              >
                <br /><br />
                <h2 style="color: white !important">Trop tard 🤷‍♂️</h2>
                <p style="color: white !important">
                  Cette collecte est terminée. <br />
                  Tu ne peux plus y participer.
                </p>

                <hr
                  style="
                    height: 1px;
                    background-color: #392774 !important;
                    color: #392774 !important;
                  "
                />
                <b-button
                  tag="a"
                  href="https://www.getinshoot.com"
                  target="blank"
                  size="is-small"
                  style="
                    background-color: #392774;

                    color: white;
                    border-radius: 5px !important;
                    margin: auto;
                  "
                  >Découvrir Getinshoot
                </b-button>
              </article>
            </div>
            <div v-else class="tile is-parent is-vertical">
              <article
                class="tile is-child notification has-text-centered"
                style="background-color: #1b1051; color: white !important"
              >
                <ShootBar
                  v-if="!overquota"
                  :allowedType="event.allowContribType"
                  :code="code"
                  :eventId="id"
                />
                <p
                  class="notification"
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    background-color: #f9664c;
                    color: white !important;
                  "
                  v-else
                >
                  Tu as atteint la limite de participation pour ce challenge
                </p>
                <hr
                  style="
                    height: 1px;
                    background-color: #392774 !important;
                    color: #392774 !important;
                  "
                />

                <p style="color: white !important">
                  Temps restant :
                  <strong style="color: white !important">{{
                    remainingTime
                  }}</strong>
                </p>
              </article>
              <article
                class="tile is-child notification"
                style="background-color: #eac335; color: #161032 !important"
              >
                <div>
                  <p class="title is-4" v-if="event.reward">
                    <img
                      v-if="event.reward == 'Reconnaissance'"
                      align="left"
                      style="width: 60px; margin-right: 10px"
                      src="../public/img/reconnaissance.png"
                    />
                    <img
                      v-if="event.reward == 'Cadeau'"
                      align="left"
                      style="width: 60px; margin-right: 10px"
                      src="../public/img/cadeau.png"
                    />
                    <img
                      v-if="event.reward == 'Concours'"
                      align="left"
                      style="width: 60px; margin-right: 10px"
                      src="../public/img/concours.png"
                    />
                    <img
                      v-if="event.reward == 'Tirage'"
                      align="left"
                      style="width: 60px; margin-right: 10px"
                      src="../public/img/tirage.png"
                    /><b-tag
                      style="
                        background-color: #161032;
                        color: #eac335;
                        margin-bottom: 10px;
                      "
                      ><strong style="text-transform: uppercase">{{
                        event.reward
                      }}</strong></b-tag
                    ><br /><i>{{ event.motivRewards }}</i>
                  </p>
                </div>
              </article>
            </div>

            <div class="tile is-parent is-2 is-vertical"></div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { DB } from "@/firebase/db";
import "firebase/firestore";
import ShootBar from "./components/shootBar.vue";
import imgBG from "../public/img/background_pictos.svg";

export default {
  name: "Event",
  props: ["id"],
  components: { ShootBar },
  filters: {
    truncate: function (text, length, suffix) {
      if (text && length && text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  data() {
    return {
      imgBG,
      readMore: false,
      event: {},
      toggler: false,
      imageList: [],
      productIndex: 0,
      isCardModalActive: false,
      timerUpdater: null,
      remainingTime: "--:--:--",
      index: null,
      code: null,
      options: {
        // The list object property (or data attribute) for video preload:
        videoPreloadProperty: "href",
        // The list object property (or data attribute) for the video poster URL:
        videoPosterProperty: "poster",
      },
      overquota: false,
    };
  },
  async beforeMount() {
    this.code = this.$route.query["invite-code"];
    this.event = (await DB.collection("events").doc(this.id).get()).data();
    if (this.event) {
      if (this.$store.getters.activeUser) {
        let userContribs = (
          await DB.collection("contributions")
            .where("eventid", "==", this.id)
            .where("userid", "==", this.$store.getters.activeUser.uid)
            .get()
        ).docs;
        this.overquota =
          this.event.mediabyguest !== 0 &&
          userContribs.length >= this.event.mediabyguest;
      }

      this.overquota =
        this.overquota ||
        (this.event.limitguest != 0 &&
          this.event.guests >= this.event.limitguest);

      this.timerUpdater = setInterval(() => {
        if (this.event) {
          if (this.event.eventduration && this.event.startedAt) {
            try {
              this.remainingTime = this.formatRemainingTime(
                new Date(
                  this.event.startedAt.toDate().getTime() +
                    this.event.eventduration
                )
              );
            } catch (e) {
              this.remainingTime = this.formatRemainingTime(
                new Date(this.event.startedAt + this.event.eventduration)
              );
            }
          }
        }
      }, 1000);
    } else {
      this.$router.push("/");
    }
  },
  mounted() {},

  methods: {
    openSlider(index) {
      this.productIndex = index;
      this.toggler = !this.toggler;
    },
    formatRemainingTime(endDate) {
      let remainingTime = endDate.getTime() - new Date().getTime();
      let remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      let remainingHours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let remainingMinutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      let remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
      return `${remainingDays}j ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`;
    },
  },
  beforeDestroy() {
    clearInterval(this.timerUpdater);
  },
};
</script>

<style src="./components/index.css">
</style>