<template>
  <section
    :style="{
      backgroundImage: ` url(${imgBG})`,
    }"
    style="background-color: #161032; height: 100vh"
  >
    <br /><br />
    <section>
      <div style="margin: 0 auto" class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-2 is-vertical"></div>
            <div class="tile is-parent">
              <article
                style="background-color: #392774; color: white"
                class="tile is-child notification has-text-centered"
              >
                <br />
                <span style="font-size: 60px">🎉</span>
                <br />
                <h2 style="color: white !important">Félicitations</h2>
                <p class="title is-6">Votre {{ type }} a bien été envoyée !</p>

                <p class="title is-6">
                  {{ event.motivRewards }}
                </p>

                <hr
                  style="
                    height: 1px;
                    background-color: #161032 !important;
                    color: #161032 !important;
                  "
                />

                <br />
                <ShootBar
                  v-if="!overquota"
                  :allowedType="event.allowContribType"
                  :code="code"
                  :eventId="id"
                />
                <p
                  class="notification"
                  style="
                    font-weight: bold;
                    font-size: 14px;
                    background-color: #f9664c;
                    color: white !important;
                  "
                  v-else
                >
                  Tu as atteint la limite de participation pour ce challenge
                </p>
              </article>
            </div>

            <div class="tile is-parent is-2 is-vertical"></div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { DB } from "@/firebase/db";
import "firebase/firestore";
import ShootBar from "./components/shootBar.vue";
import imgBG from "../public/img/background_pictos.svg";

export default {
  name: "Success",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  components: { ShootBar },
  data() {
    return {
      imgBG,
      event: {},
      type: "",
      overquota: true,
      code: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.code = this.$route.query.code;
  },
  async beforeMount() {
    this.event = (await DB.collection("events").doc(this.id).get()).data();
    let userContribs = (
      await DB.collection("contributions")
        .where("eventid", "==", this.id)
        .where("userid", "==", this.$store.getters.activeUser.uid)
        .get()
    ).docs;
    this.overquota =
      this.event.mediabyguest !== 0 &&
      userContribs.length >= this.event.mediabyguest;
  },
};
</script>

<style src="./components/index.css">
</style>