<template>
  <div style="background-color: transparent">
    <footer class="recorder-footer">
      <p
        class="recorder-footer-item"
        v-if="!allowedType || allowedType != 'video'"
      >
        <span>
          <router-link
            :to="'/recorder/' + eventId + '?type=photo&code=' + code"
          >
            <figure>
              <img style="width: 60px" src="../../public/img/photo2.png" />
            </figure>
          </router-link>
          <small class="title is-4">photo </small>
        </span>
      </p>
      <p
        class="recorder-footer-item"
        v-if="!allowedType || allowedType != 'photo'"
      >
        <span>
          <router-link
            :to="'/recorder/' + eventId + '?type=video&code=' + code"
          >
            <figure>
              <img style="width: 60px" src="../../public/img/video2.png" />
            </figure>
          </router-link>
          <small class="title is-4">video </small>
        </span>
      </p>

      <p class="recorder-footer-item">
        <span>
          <router-link :to="'/uploader/' + eventId + '?code=' + code">
            <figure>
              <img style="width: 60px" src="../../public/img/import2.png" />
            </figure>
          </router-link>
          <small class="title is-4">import </small>
        </span>
      </p>
    </footer>
  </div>
</template>

  

   


<script>
export default {
  name: "shootBar",
  props: ["eventId", "allowedType", "code"],
  data() {
    return {};
  },
};
</script>

<style src="./index.css">
</style>