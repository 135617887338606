<template>
  <section class="box" style="box-shadow: none">
    <h2>Rejoindre le challenge</h2>
    <br />
    <p class="title is-4">
      • je cède mes droits au makerz ${event.host} ⚖️ <br />
      • je demande le consentement des personnes avant de les filmer 😇 <br />•
      je vérifie et valide avant d'envoyer une contribution au makerz 🧐 <br />•
      j'ai lu et je respecte les CGU 🤓 <br />• je prends du plaisir à
      participer au projet 🤩<br />
    </p>
    <b-button type="is-warning" @click="join">
      <i class="fas fa-validate"></i>
      Accepter et Rejoindre
    </b-button>
  </section>
</template>

<script>
import { DB } from "@/firebase/db";
import "firebase/firestore";

export default {
  name: "Join",
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async join() {
      let loadingComponent = this.$buefy.loading.open();
      let user = await DB.collection("users")
        .doc(this.$store.getters.activeUser.uid)
        .get();
      let code = (
        await DB.collection("eventcodes")
          .where("eventid", "==", this.event.id)
          .get()
      ).docs[0]?.id;

      await DB.collection("eventcoderequests").add({
        userid: user.id,
        eventid: this.event.id,
        created: new Date(),
        codeinvite: code,
      });

      let unsub = DB.collection("invitations")
        .where("eventid", "==", this.event.id)
        .where("userid", "==", user.id)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            if (!doc.data().joined) {
              await DB.collection("invitationactions").add({
                userid: user.id,
                eventid: this.event.id,
                created: new Date(),
                modified: new Date(),
                joined: true,
                invitationid: doc.id,
              });
            } else {
              loadingComponent.close();
              unsub();
              this.$buefy.toast.open({
                message: "Vous avez rejoint l'évenement",
                type: "is-success",
                queue: false,
                duration: 3000,
              });
              this.$router.push("/challenge/" + this.event.id);
            }
          });
        });
    },
  },
};
</script>

<style src="./index.css">
</style>