<template>
  <section class="padding">
    <nav
      class="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item" style="background-color: #161032">
          <router-link to="/">
            <img src="./logo.png" width="130" style="margin-top: 5px"
          /></router-link>
        </a>
        <a class="navbar-item-profile">
          <router-link to="/profile">
            <figure
              v-if="$store.getters.activeUser && user && user.thumbnailURL"
              class="image is-inline-block"
              style="width: 30px; height: 30px"
            >
              <img
                class="is-rounded"
                style="object-fit: cover !important; height: 100%"
                alt="profil"
                :src="user.thumbnailURL"
              />
            </figure>

            <figure v-else>
              <img
                width="30px"
                alt="profil"
                src="../../public/img/account.png"
              /></figure
          ></router-link>
        </a>
      </div>
    </nav>
  </section>
</template>

<script>
import { DB } from "@/firebase/db";

export default {
  components: {},

  data() {
    return {
      user: {},
    };
  },

  mounted() {
    var user = this.$store.getters.activeUser;
    if (user) {
      DB.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          this.user = doc.data();
        });
    }
  },
};
</script>

<style>
</style>