<template>
  <section style="background-color: #161032">
    <div style="margin: 0 auto" class="tile is-ancestor">
      <div class="tile is-vertical is-12">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article
              class="tile is-child notification has-text-centered"
              style="background-color: transparent"
            >
              <p style="color: white !important">Source camera</p>
              <b-field>
                <b-select v-model="videoId" placeholder="Select a source">
                  <option
                    v-for="device in videoDevices"
                    :value="device.deviceId"
                    :key="device.deviceId"
                  >
                    {{ device.label }}
                  </option>
                </b-select>
              </b-field>
              <p style="color: white !important">Source audio</p>
              <b-field>
                <b-select v-model="audioId" placeholder="Select a source">
                  <option
                    v-for="device in audioDevices"
                    :value="device.deviceId"
                    :key="device.deviceId"
                  >
                    {{ device.label }}
                  </option>
                </b-select>
              </b-field>
              <br />
              <b-button
                @click="
                  $emit('updateInputDevices', {
                    video: videoId,
                    audio: audioId,
                  })
                "
                style="
                  background-color: #17bebb;
                  color: white;
                  font-weight: bold;
                "
                >sauvegarder</b-button
              >
            </article>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "firebase/firestore";

export default {
  name: "settings",
  props: ["devices", "audioDeviceId", "videoDeviceId"],
  components: {},
  data() {
    return {
      videoDevices: this.devices.filter((d) => d.kind == "videoinput"),
      audioDevices: this.devices.filter((d) => d.kind == "audioinput"),
      audioId: this.audioDeviceId,
      videoId: this.videoDeviceId,
    };
  },
};
</script>

<style src="./index.css">
</style>