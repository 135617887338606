<template>
  <div style="background-color: white" class="has-text-centered">
    <div v-if="toggleLogin">
      <form novalidate @submit.prevent="submit">
        <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="googleSignUp"
          ><img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/google.png"
          />{{ "s'inscrire avec google" }}</b-button
        >
        <!-- <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="appleSignUp"
        >
          <img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/apple.png"
          />{{ "s'inscrire avec apple" }}</b-button
        > -->
        <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="facebookSignUp"
          ><img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/facebook.png"
          />{{ "s'inscrire avec facebook" }}</b-button
        >

        <div
          style="
            width: 100%;
            height: 15px;
            border-bottom: 1px solid #f3f5f6;
            text-align: center;
          "
        >
          <span
            style="
              font-size: 10px;
              color: #161032;
              background-color: white;
              padding: 0 10px;
            "
          >
            OU
          </span>
        </div>
        <br />

        <p
          v-if="errors.length"
          class="notification"
          style="
            font-size: 12px;
            font-weight: bold;
            border: 1px solid #f14668;
            background-color: white;
            color: #f14668 !important;
          "
        >
          <list-group>
            <list-group-item
              v-for="error in errors"
              :key="error"
              class="list-group-item-danger"
            >
              {{ error }}
            </list-group-item>
          </list-group>
        </p>

        <b-field label="Email" :label-position="labelPosition">
          <b-input
            v-model="email"
            type="email"
            required
            invalidFeedback="Please provide an email"
          />
        </b-field>
        <br />
        <b-field label="Mot de passe" :label-position="labelPosition">
          <b-input
            v-model="password"
            type="password"
            required
            invalidFeedback="Please provide a password"
          />
        </b-field>

        <br />
        <div class="text-center">
          <b-button
            tag="input"
            style="background-color: #17bebb; color: white"
            native-type="submit"
            value="créer mon compte gratuit"
          ></b-button>
        </div>
      </form>
    </div>
    <div v-else>
      <form novalidate @submit.prevent="login">
        <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="googleSignUp"
          ><img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/google.png"
          />{{ "se connecter avec google" }}</b-button
        >
        <!-- <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="appleSignUp"
        >
          <img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/apple.png"
          />{{ "se connecter avec apple" }}</b-button
        > -->
        <b-button
          size="is-default"
          style="color: #161032; border: 1px solid #161032 !important"
          expanded
          class="mb-2"
          @click.native="facebookSignUp"
          ><img
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../../../public/img/facebook.png"
          />{{ "se connecter avec facebook" }}</b-button
        >

        <div
          style="
            width: 100%;
            height: 15px;
            border-bottom: 1px solid #f3f5f6;
            text-align: center;
          "
        >
          <span
            style="
              font-size: 10px;
              color: #161032;
              background-color: white;
              padding: 0 10px;
            "
          >
            OU
          </span>
        </div>
        <br />
        <p
          v-if="errors.length"
          class="notification"
          style="
            font-size: 12px;
            font-weight: bold;
            border: 1px solid #f14668;
            background-color: white;
            color: #f14668 !important;
          "
        >
          <list-group>
            <list-group-item
              v-for="error in errors"
              :key="error"
              class="list-group-item-danger"
            >
              {{ error }}
            </list-group-item>
          </list-group>
        </p>
        <b-field label="Email" :label-position="labelPosition">
          <b-input
            v-model="email"
            type="email"
            required
            invalidFeedback="Please provide an email"
          />
        </b-field>
        <br />
        <b-field label="Mot de passe" :label-position="labelPosition">
          <b-input
            v-model="password"
            type="password"
            required
            invalidFeedback="Please provide a password"
          />
        </b-field>

        <br />
        <div class="text-center">
          <b-button
            tag="input"
            native-type="submit"
            value="se connecter"
            type="is-primary"
          ></b-button>
        </div>
      </form>
    </div>
    <br />
    <span
      v-if="toggleLogin"
      @click="toggleLogin = !toggleLogin"
      style="cursor: pointer; font-size: 12px !important; font-weight: bold"
    >
      Vous avez déjà un compte ? <u>Connectez-vous maintenant</u>
    </span>
    <span
      v-else
      @click="toggleLogin = !toggleLogin"
      style="cursor: pointer; font-size: 12px !important; font-weight: bold"
      >Vous n'avez pas de compte ? <u>Inscrivez-vous maintenant</u></span
    >

    <br />
  </div>
</template>
<script>
import { Auth } from "../../firebase/auth";
import validate from "../../modules/validation-module";
import imgBG from "../../../public/img/background_pictos.svg";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      labelPosition: "on-border",
      toggleLogin: true,
      imgBG,
      email: "",
      password: "",
      confirmPassword: "",
      errors: [],
    };
  },
  props: {
    returnType: {
      type: String,
      default: "root",
    },
  },
  methods: {
    login(event) {
      this.errors = [];
      if (!validate.email(this.email)) {
        this.errors.push("Adresse email invalide.");
      }
      if (!this.errors.length) {
        event.target.classList.add("was-validated");
        this.$store
          .dispatch("signInWithEmail", {
            email: this.email,
            pwd: this.password,
          })
          .then(() => {
            this.handleResult();
          })
          .catch((err) => this.errors.push(err.message));
      }
    },
    submit(event) {
      this.errors = [];
      if (!validate.email(this.email)) {
        this.errors.push("Adresse email invalide.");
      }
      if (!validate.password(this.password)) {
        this.errors.push(
          "Votre mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial."
        );
      }

      if (!this.errors.length) {
        event.target.classList.add("was-validated");
        Auth.createUserWithEmailAndPassword(this.email, this.password)
          .then((created) => {
            created.user.sendEmailVerification().then(() => {
              this.handleResult();
            });
          })
          .catch((error) => {
            this.errors.push(error.message);
          });
      }
    },

    googleSignUp() {
      this.$store
        .dispatch("signInWithGoogle")
        .then(() => {
          this.handleResult();
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },
    facebookSignUp() {
      this.$store
        .dispatch("signInWithFacebook")
        .then(() => {
          this.handleResult();
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },
    appleSignUp() {
      this.$store
        .dispatch("signInWithApple")
        .then(() => {
          this.handleResult();
        })
        .catch((error) => {
          console.error(error);
          this.errors.push(error.message);
        });
    },
    handleResult() {
      switch (this.returnType) {
        case "root":
          if (!this.$router.currentRoute.path == "/") {
            this.$router.push("/");
          }
          break;
        case "profile":
          this.$router.push("/profile");
          break;
        default:
          this.$emit(this.returnType);
      }
    },
  },
};
</script>

<style>
.button.is-small:not(.is-rounded) {
  border-radius: 33px !important;
}

.button {
  border-width: 0px !important;
}

.sepLog {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}
</style>

