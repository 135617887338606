<template>
  <div>
    <Header />
    <router-view></router-view>
    <!-- <add-to-homescreen
      lang="fr_FR"
      buttonColor="#17Bebb"
      title=""
      titleColor="#161032"
      contentColor="#161032"
      iconPath="https://www.getinshoot.com/wp-content/uploads/2020/04/Icon-App-83.5x83.5@2x.png"
      iconColor="#161032"
      content="Ajoute un raccourci vers Getinshoot pour retrouver plus rapidement tes challenges et y participer."
    /> -->
  </div>
</template>

<script>
import Header from "../components/header.vue";

export default {
  name: "App",

  components: {
    Header,
  },
};
</script>

<style >
</style>