<template>
  <div
    class="section"
    style="
      background-color: white !important;
      padding: 40px;
      border-radius: 15px;
    "
  >
    <div class="media">
      <div class="media-left">
        <figure class="image is-128x128">
          <img src="../../public/img/valider-inscription.png" />
        </figure>
      </div>
      <div class="media-content">
        <div class="media-content" style="text-align: left">
          <h2>Validez votre participation à {{ eventName }}</h2>
          <br />
          <p style="text-align: left !important; color: #161032;">
            <strong>
              Pour envoyer votre contribution vous devez céder vos droits à
              l’image à l’organisateur. Cette cession ne sera demandée qu'une
              seule fois pour toute vos contributions.</strong
            >

            <br />
          </p>
        </div>
      </div>
    </div>
    <br />
    <b-field label="Prénom" style="text-align: left">
      <b-input v-model="surname"></b-input>
    </b-field>

    <b-field label="Nom" style="text-align: left">
      <b-input v-model="name"></b-input>
    </b-field>

    <div class="tile">
      <div class="tile is-6" style="padding-right: 20px">
        <b-field label="Date de naissance" style="text-align: left">
          <b-datepicker
            v-model="birthDate"
            placeholder="choisir..."
            icon="calendar-today"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
      <div class="tile is-6">
        <b-field label="Code Postal" style="text-align: left">
          <b-input v-model="postalCode"></b-input>
        </b-field>
      </div>
    </div>
    <br />
    <b-field label="Email" style="text-align: left">
      <b-input v-model="email"></b-input>
    </b-field>

    <p style="text-align: left !important; color: #161032;">
      • Je comprends que mon fichier sera envoyé à {{ host }} <br />

      • Je cède gratuitement mes droits à l'image à {{ host }} <br />

      • J'ai lu et j'accepte les
      <a
        href="
                https://www.getinshoot.com/en/privacy/ 
              "
        target="_blank"
        >CGU de Getinshoot</a
      ><br /><br />
    </p>

    <b-button
      type="is-primary"
      expanded
      :loading="loading"
      :disabled="!name || !surname || !email || !birthDate || !postalCode"
      @click="join()"
      >Je valide</b-button
    >
    <Terms
      ref="rights"
      style="display: none"
      :guestSurName="surname"
      :guestLastName="name"
      :guestEmail="email"
      :postalCode="postalCode"
      :birthDate="new Date(birthDate).toLocaleDateString()"
      :hostName="host"
      :recordDay="new Date().toLocaleDateString()"
      :recordHour="new Date().toLocaleTimeString()"
    />
  </div>
</template>

<script>
import { DB } from "@/firebase/db";
import Terms from "./terms.vue";
export default {
  name: "rightsCession",
  components: {
    Terms,
  },
  props: ["host", "eventName", "eventId", "code"],
  data() {
    return {
      loading: false,
      name: "",
      surname: "",
      birthDate: null,
      postalCode: "",
      email: "",
    };
  },
  methods: {
    async join() {
      console.log(this.$refs.rights.$el.innerHTML);
      this.loading = true;

      await DB.collection("users")
        .doc(this.$store.getters.activeUser.uid)
        .update({
          name: this.surname + " " + this.name,
          birthDate: this.birthDate,
          postalCode: this.postalCode,
          email: this.email,
        });

      await DB.collection("eventcoderequests").add({
        userid: this.$store.getters.activeUser.uid,
        eventid: this.eventId,
        created: new Date(),
        terms: this.$refs.rights.$el.innerHTML,
        codeinvite: this.code,
      });

      let unsub = DB.collection("invitations")
        .where("eventid", "==", this.eventId)
        .where("userid", "==", this.$store.getters.activeUser.uid)
        .onSnapshot((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            if (!doc.data().joined) {
              await DB.collection("invitationactions").add({
                userid: this.$store.getters.activeUser.uid,
                eventid: this.eventId,
                created: new Date(),
                modified: new Date(),
                joined: true,
                invitationid: doc.id,
              });
            } else {
              unsub();
              this.$buefy.toast.open({
                message: "Vous avez rejoint l'évenement",
                type: "is-success",
                queue: false,
                duration: 3000,
              });

              this.$emit("validated");
            }
          });
        });
    },
  },
};
</script>

<style src="./index.css">
</style>