import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { Auth } from "@/firebase/auth";
import { GoogleAuthProvider, FacebookAuthProvider, } from "firebase/auth";


export default new Vuex.Store({
    state: {
        user: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        }
    },
    actions: {
        autoSignIn({ commit }, payload) {
            commit('setUser', payload)
        },
        signInWithEmail({ commit }, payload) {
            console.info(commit);
            return Auth.signInWithEmailAndPassword(payload.email, payload.pwd);
        },
        signInWithGoogle() {
            return Auth.signInWithPopup(new GoogleAuthProvider());
        },
        signInWithFacebook() {
            return Auth.signInWithPopup(new FacebookAuthProvider());
        },
        // signInWithApple() {
        //     return Auth.signInWithRedirect(new OAuthProvider('apple.com')).catch(function (error) { console.error(error) });
        // }
        anonymousLogin() {
            return Auth.signInAnonymously();
        }
    },
    getters: {
        activeUser: (state) => {
            return state.user
        },
    }
})