<template>
  <section
    :style="{
      backgroundImage: ` url(${imgBG})`,
    }"
    style="background-color: #ffffff; height: 100vh"
  >
    <div
      class="tile is-ancestor"
      v-if="$store.getters.activeUser == null"
      style="box-shadow: none; margin: auto"
    >
      <div class="tile is-parent is-2"></div>
      <div class="tile is-parent is-8">
        <div
          class="tile is-child is-7 notification"
          style="background-color: white"
        >
          <h2>Inscrivez-vous gratuitement</h2>
          <br />
          <p>
            En vous inscrivant gratuitement sur Getinshoot, vous pourrez valider
            vos futures participations plus rapidement et contribuer à un
            maximum de projets !
          </p>
          <img
            width="100%"
            style="padding: 15px; vertical-align: middle"
            alt="Getinshoot logo"
            src="../public/img/landing.png"
          />
        </div>
        <div class="tile is-child is-5">
          <br />
          <Login />
        </div>
      </div>
      <div class="tile is-parent is-2"></div>
    </div>

    <div v-else class="" style="box-shadow: none">
      <Joined />
      <br />
      <div
        class="tile is-parent notification tile is-10"
        style="background-color: white; border: 1px solid #eae4f8; margin: auto"
      >
        <div class="tile is-9">
          <p style="font-size: 14px">
            <b-icon icon="lightbulb-on" style="color: #eac335" size="is-small">
            </b-icon>
            Si vous êtes une entreprise ou une association, Getinshoot est la
            meilleure solution pour récupérer instantanément le contenu créé par
            votre communauté et placer l’humain au cœur de votre stratégie de
            communication.
          </p>
        </div>
        <div class="tile is-3 has-text-centered">
          <b-button
            tag="a"
            href="https://www.getinshoot.com"
            target="blank"
            size="is-small"
            style="
              background-color: #392774;

              color: white;
              border-radius: 5px !important;
              margin: auto;
            "
            >Découvrir Getinshoot
          </b-button>
        </div>
      </div>
      <br />
    </div>
  </section>
</template>

<script>
import Joined from "./components/joined.vue";
import Login from "../src/views/user/Login.vue";
import imgBG from "../public/img/background_pictos.svg";
import { Auth } from "@/firebase/auth";

export default {
  name: "Home",
  components: {
    Joined,
    Login,
  },
  data() {
    return {
      imgBG,
    };
  },
  created() {
    Auth.getRedirectResult()
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style src="./components/index.css">
</style>


