<template >
  <section :style="{
    backgroundImage: ` url(${imgBG})`,
  }" style="background-color: #161032; height: 100vh;">
    <section>
      <div style="margin: 0 auto" class="tile is-ancestor">
        <div class="tile is-vertical is-12">
          <div class="tile">
            <div class="tile is-parent is-2 is-vertical"></div>
            <div class="tile is-parent videoRecorderResponsive">
              <article class="tile is-child">
                <span>
                  <img @click="$router.go(-1)" style="width: 25px; padding-top: 10px; padding-left: 10px"
                    src="../../public/img/back-camera.png" />
                </span>

                <div class="has-text-centered" style="
                    margin-bottom: 1em;
                    margin-top: 1em;
                    height: fit-content;
                  ">
                  <span v-if="!shootDone && type == 'video'" class="timer" style="font-size: 40px; color: white">{{
                  remainingDuration }}</span>
                  <span v-if="shootDone" class="timer" style="font-size: 20px; color: white">si vous aimez, validez pour
                    envoyer</span>
                  <span v-else class="timer" style="font-size: 40px; color: #392774"></span>
                </div>

                <div class="videoContainer" ref="videoContainer" id="videoContainer" v-if="!uploading">
                  <video id="recorderVideo" v-if="!shootDone" style="margin: auto" playsinline
                    class="video-js vjs-default-skin .vjs-1-1"></video>
                    <video
                      id="playerVideo"
                      ref="playerVideo"
                      playsinline
                      preload='metadata'
                      style="display:none"
                      autoplay
                      class="video-js vjs-default-skin .vjs-1-1 customVideo playerVideo"
                    ></video>
                </div>
                <div v-else>
                  <b-progress type="is-info" :max="100" :value="progress" show-value size="is-medium"
                    style="margin: 50px">
                  </b-progress>
                </div>
                <br />
                <footer v-if="shootSuccess" class="recorder-footer"></footer>
                <footer v-if="shootDone" class="recorder-footer">
                  <p class="recorder-footer-item">
                    <span>
                      <b-button style="
                          background-color: #392774;
                          border: 1px solid white !important;
                          color: white;
                        " :disabled="uploading" @click="reset()" size="is-default">recommencer</b-button>
                    </span>
                  </p>

                  <p v-if="!user" class="recorder-footer-item">
                    <span>
                      <b-button style="
                          background-color: #17bebb;
                          color: white;
                          font-weight: bold;
                        " size="is-default" @click="checkRights()">valider ❤️</b-button>
                    </span>
                  </p>
                  <p v-else class="recorder-footer-item">
                    <span>
                      <b-button style="
                          background-color: #17bebb;
                          color: white;
                          font-weight: bold;
                        " size="is-default" :disabled="uploading" @click="checkRights()">valider ❤️</b-button>
                    </span>
                  </p>

                  <b-modal v-model="showRightsModal" :width="640" scroll="keep">
                    <RightsCession :host="eventHost" :eventName="eventName" :eventId="eventId" :code="code"
                      v-on:validated="checkRights()" />
                  </b-modal>
                </footer>

                <footer v-else class="recorder-footer">
                  <p class="recorder-footer-item">
                    <span>
                      <figure v-if="isRecordingVideo"></figure>
                      <figure v-else>
                        <img @click="isModalSettings = true" style="width: 60px" src="../../public/img/setting.png" />
                      </figure>
                    </span>
                  </p>
                  <b-modal v-model="isModalSettings" :width="640" scroll="keep">
                    <Settings :devices="devices" :audioDeviceId="audioDeviceId" :videoDeviceId="deviceId"
                      v-on:updateInputDevices="updateDevices" />
                  </b-modal>
                  <p class="recorder-footer-item">
                    <span>
                      <figure v-if="type == 'photo'">
                        <img style="width: 85px" src="../../public/img/shoot_photo_button.png" @click="record()" />
                      </figure>
                      <figure v-if="isRecordingVideo && type == 'video'">
                        <img style="width: 85px" src="../../public/img/stop-video-button.png" @click="stop()" />
                      </figure>
                      <figure v-if="!isRecordingVideo && type == 'video'">
                        <img style="width: 85px" src="../../public/img/shoot_video_button.png" @click="record()" />
                      </figure>
                    </span>
                  </p>
                  <p class="recorder-footer-item">
                    <span>
                      <figure v-if="!isRecordingVideo && isMobile()">
                        <img @click="swipeCamera()" style="width: 60px" src="../../public/img/reverse.png" />
                      </figure>
                    </span>
                  </p>
                </footer>
              </article>
            </div>

            <div class="tile is-parent is-2 is-vertical"></div>
          </div>
        </div>
      </div>
    </section>

    <br />
    <br />
  </section>
</template>



<script>
/* eslint-disable */
import { v4 as uuidv4 } from "uuid";
import { DB } from "@/firebase/db";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";

import "webrtc-adapter";
import imgBG from "./../../public/img/background_pictos.svg";
import RightsCession from "./rightsCession.vue";
import Settings from "./settings.vue";
import Login from "./../views/user/Login.vue";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
export default {
  name: "recorder",
  components: {
    RightsCession,
    Login,
    Settings,
  },
  props: {
    eventId: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "video",
    },
    code: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      eventName: "",
      eventHost: "",
      imgBG,
      user: {},
      showRightsModal: false,
      isModalSettings: false,
      isChromeIOS: false,
      uploading: false,
      progress: 0,
      options: {
        // video.js options
        controls: false,
        bigPlayButton: false,
        autoplay: true,
        fluid: true,
        width: 1920,
        height: 1080,
        aspectRatio: "1:1",
        controlBar: {
          volumePanel: true,
          fullscreenToggle: false,
          recordIndicator: false,
          cameraButton: false,
          recordToggle: false,
          pipToggle: false,
          deviceButton: false,
        },
        plugins: {
          // videojs-record plugin options
          record: {
            image: false,
            audio: true,
            video: {
              // video media constraints: set resolution of camera
              width: { min: 640, ideal: 1920, max: 1920 },
              height: { min: 480, ideal: 1080, max: 1080 },
              frameRate: { ideal: 25, max: 30 },
              aspectRatio: 1.7777777778,
              facingMode: "user",
            },
            maxLength: 500,
            frameWidth: 1920,
            frameHeight: 1080,
            timeSlice: 100,
            imageOutputFormat: "image/png",
            imageOutputQuality: 0.92,
            imageOutputType: "dataURL",
            displayMilliseconds: true,
            debug: false,
            audioSampleRate: 48000,
            bufferSize: 4096,
            videoRecorderType: 'auto',
            debug: true,
          },
        },
      },
      file: null,
      player: null,
      devices: [],
      deviceId: "default",
      audioDeviceId: "default",
      isCardModalActive: false,
      remainingDuration: "--:--",
      shootDone: false,
      shootSuccess: false,
      isRecordingVideo: false,
    };
  },
  methods: {
    play() {
      this.player.controls(true);
      this.player.play();
    },
    updateDevices(event) {
      this.isModalSettings = false;
      this.deviceId = event.video;
      this.audioDeviceId = event.audio;
      this.player.record().setVideoInput(this.deviceId);
      this.player.record().setAudioInput(this.audioDeviceId);
      this.player.record().getDevice();
    },

    isMobile() {
      return window.innerWidth < 600;
    },
    record() {
      if (this.type == "video") {
        this.player.record().start();
        this.isRecordingVideo = true;
      } else {
        // create new canvas
        var canvas = document.createElement("canvas");
        let width = window.innerWidth;
        let height = window.innerHeight;
        if (width > height) {
          canvas.width = 1920;
          canvas.height = 1080;
        } else {
          canvas.width = 1080;
          canvas.height = 1920;
        }

        this.shootDone = true;
        // draw video frame
        var context = canvas.getContext("2d");
        context.drawImage(
          this.player.record().mediaElement,
          0,
          0,
          canvas.width,
          canvas.height
        );
        var target = new Image();
        target.src = canvas.toDataURL();
        target.id = "imgresult";
        if (width < height) {
          target.style.width = "100%";
          target.style.height = "auto";
          target.style.top = "50%";
          target.style.left = "0";
          target.style.transform = "translate(0, -50%)";
        } else {
          target.style.width = "auto";
          target.style.height = "100%";
          target.style.top = "0";
          target.style.left = "50%";
          target.style.transform = "translate(-50%, 0)";
        }
        target.style.maxWidth = "none";
        target.style.maxHeight = "none";
        target.style.position = "absolute";

        document.getElementById("videoContainer").appendChild(target);
      }
    },
    stop() {
      if (this.isRecordingVideo) {
        this.player.record().stop();
        if (this.isChromeIOS) {
          this.player.record().stopDevice();
        }
      }
    },
    reset() {
      this.loading = false;
      this.shootDone = false;
      //this.isActive = false;
      this.validated = false;
      this.toRecord = true;
      if (this.type == "photo") {
        document
          .getElementById("videoContainer")
          .removeChild(document.getElementById("imgresult"));
      }
      this.player.record().reset();
      this.player.record().loadOptions(this.options.plugins.record);
      if(this.deviceId !== 'default') {
        this.player.record().setVideoInput(this.deviceId);
      }
      if (this.audioDeviceId !== 'default') {
        this.player.record().setAudioInput(this.audioDeviceId);
      }
      this.player.record().getDevice();
      //this.deviceId = "default";
      this.remainingDuration = this.formatTime(
        this.options.plugins.record.maxLength
      );
      if (this.isChromeIOS) {
        this.$refs.playerVideo.style.display = 'none';
        document.getElementById('playerVideo').style.display = 'none';
        this.playerVideo.pause();
        console.log('resetting');
      }
      if (this.player) {
        this.player.record().reset();
      }
      this.shootSuccess = false;
      this.isRecordingVideo = false;
    },


    async checkRights() {
      if (this.$store.getters.activeUser) {
        let invitation = (
          await DB.collection("invitations")
            .where("eventid", "==", this.eventId)
            .where("userid", "==", this.$store.getters.activeUser.uid)
            .get()
        ).docs[0];
        if (invitation && invitation.data().joined) {
          this.showRightsModal = false;
          this.uploadContrib();
        } else {
          if (this.eventName == "") {
            DB.collection("events")
              .doc(this.eventId)
              .get()
              .then((doc) => {
                this.remainingDuration = this.formatTime(
                  doc.data().mediaduration
                );
                this.options.plugins.record.maxLength =
                  doc.data().mediaduration;
                this.eventName = doc.data().name;
                this.eventHost = doc.data().host;
                this.player.record().loadOptions(this.options.plugins.record);
                this.reset();

                this.showRightsModal = true;
              });
          } else {
            this.showRightsModal = true;
          }
        }
      } else {
        this.anonymousLogin();
      }
    },
    async anonymousLogin() {
      await this.$store.dispatch("anonymousLogin");
      this.checkRights();
    },
    async uploadContrib() {
      if (this.type == "photo") {
        this.player.recordedData = document.getElementById("imgresult").src;
      }
      let filename = uuidv4();
      let storagePath =
        "participations/" +
        this.eventId +
        (this.type == "video" ? "/videos/" : "/photos/") +
        this.$store.getters.activeUser.uid +
        "/" +
        filename +
        (this.type == "video" ? ".mp4" : ".png");

      let datas = {
        userid: this.$store.getters.activeUser.uid,
        eventid: this.eventId,
        type: this.type,
        format: this.type == "video" ? "mp4" : "png",
        file: filename + (this.type == "video" ? ".mp4" : ".png"),
        from: this.$store.getters.activeUser.email,
        length: 0,
        created: new Date(),
        modified: new Date(),
        fileURL: "",
        size: 0,
      };

      let blob = null;
      if (this.isChromeIOS) {
        blob = this.recordedData;
        this.$refs.playerVideo.style.display = 'none';
        document.getElementById('playerVideo').style.display = 'none';
        this.playerVideo.pause();
      } else {
        blob = this.player.recordedData;
        this.player.pause();
      }
      
      if (this.type == "video") {
        datas["duration"] = this.player.record().getDuration();
      } else {
        blob = this.dataURLtoBlob(this.player.recordedData);
      }

      const storage = getStorage();
      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          switch (snapshot.state) {
            case "running":
              this.uploading = true;
              break;
          }
        },
        (error) => {
          console.error(error);
        },
        async () => {
          try {
            datas.fileURL = await getDownloadURL(uploadTask.snapshot.ref);
            await DB.collection("contributions").add(datas);
            this.$router.push(
              "/success/" +
              this.eventId +
              "?type=" +
              this.type +
              "&code=" +
              this.code
            );
          } catch (e) {
            this.$buefy.toast.open({
              message: "Erreur inatendue",
              type: "is-danger",
              position: "is-top",
            });
          }
        }
      );
    },
    swipeCamera() {
      if (this.options.plugins.record.video.facingMode == "user") {
        this.options.plugins.record.video.facingMode = "environment";
      } else {
        this.options.plugins.record.video.facingMode = "user";
      }
      this.player.record().reset();
      this.player.record().loadOptions(this.options.plugins.record);
      this.player.record().getDevice();
    },
    formatTime(time) {
      var hr = ~~(time / 3600);
      var min = ~~((time % 3600) / 60);
      var sec = time % 60;
      var sec_min = "";
      if (hr > 0) {
        sec_min += "" + hrs + ":" + (min < 10 ? "0" : "");
      }
      sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
      sec_min += "" + sec;
      return sec_min;
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    computeRecorderSize() {
      let width = window.innerWidth;
      let height = window.innerHeight;
      let computedWidth = width;
      let computedHeight = height;
      if (width > height) {
        computedWidth = (height * 60) / 100;

        computedHeight = computedWidth;
      } else {
        computedHeight = (width * 90) / 100;
        computedWidth = computedHeight;
      }
      if (this.$refs.videoContainer) {
        this.$refs.videoContainer.style.width =
          Math.round(computedWidth / 10) * 10 + "px";
        this.$refs.videoContainer.style.height =
          Math.round(computedHeight / 10) * 10 + "px";
      }
    },
    async getCapabilities(device) {
      let constraint = (constraint = {
        video: {
          facingMode: { exact: this.options.plugins.record.video.facingMode },
        },
      });
      if (device) {
        constraint = {
          video: {
            facingMode: { exact: this.options.plugins.record.video.facingMode },
          },
        };
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraint);
      stream.getVideoTracks().forEach((track) => {
        const capabilities = track.getCapabilities();
        if (
          this.options.plugins.record.frameWidth != capabilities.width.max ||
          this.options.plugins.record.frameHeight != capabilities.height.max
        ) {
          this.options.plugins.record.frameWidth = capabilities.width.max;
          this.options.plugins.record.frameHeight = capabilities.height.max;
          this.options.plugins.record.image.width = capabilities.width.max;
          this.options.plugins.record.image.height = capabilities.height.max;

          this.player.record().reset();
          this.player.record().loadOptions(this.options.plugins.record);
          this.player.record().getDevice();
        }
      });
    },
    setUpPlayer() {
      if (this.isChromeIOS) {
        this.options.plugins.record.videoRecorderType =
          RecordRTC.MediaStreamRecorder;
      }
      this.player = videojs("#recorderVideo", this.options, () => {
        // print version information at startup
        const msg =
          "Using video.js " +
          videojs.VERSION +
          " with videojs-record " +
          videojs.getPluginVersion("record");
        videojs.log(msg);

        this.player.record().reset();
        this.player.record().getDevice();
      });
      this.player.on("enumerateReady", () => {
        this.devices = this.player.record().devices;
      });
      // device is ready
      this.player.on("deviceReady", () => {
        this.player.record().enumerateDevices();
      });

      // user clicked the record button and started recording
      this.player.on("startRecord", () => {
        this.isRecordingVideo = true;
      });

      // user completed recording and stream is available
      this.player.on("finishRecord", () => {
        // the blob object contains the recorded data that
        // can be downloaded by the user, stored on server etc.
        if (this.isChromeIOS) {
          const data = this.recordedData;
          let concatenatedData = new Blob(data, {
            type: 'video/mp4',
            name: data[data.length - 1].name,
            lastModified: data[data.length - 1].lastModified,
            lastModifiedDate: data[data.length - 1].lastModifiedDate
          });
          this.recordedData = concatenatedData;
          //TEST FFMPEG
          //concatenatedData = await this.testFfmpeg(concatenatedData, 'mov');

          console.log('concatenatedData : ', concatenatedData);
          console.log(
            'object url: ',
            window.URL.createObjectURL(concatenatedData)
          );
          this.$refs.playerVideo.style.display = 'block';
          document.getElementById('playerVideo').style.display = 'block';
          if (!this.playerVideo) {
            this.playerVideo = videojs(
              '#playerVideo',
              {
                controls: true,
                width: 320,
                height: 240,
                controlBar: {
                  volumeMenuButton: false
                },
                bigPlayButton: false
              },
              () => {
                this.playerVideo.controls(true);
                console.log('video player is ready');
                this.playerVideo.on('loadeddata', () => {
                  this.playerVideo.play();
                });

                var blobUrl = URL.createObjectURL(concatenatedData);
                this.playerVideo.src({
                  type: 'video/mp4',
                  src: blobUrl
                });
              }
            );
          } else {
            var blobUrl = URL.createObjectURL(concatenatedData);
            this.playerVideo.src({
              type: 'video/mp4',
              src: blobUrl
            });
          }

          this.player.recordedData = concatenatedData;
        }
        this.shootDone = true;
        this.isRecordingVideo = false;
        if (this.type == "video") {
          this.player.controls(true);

          // let blobUrl = window.URL.createObjectURL(this.player.recordedData);
          // this.player.record().load(blobUrl);
        }
      });

      this.player.on("timestamp", (event) => {
        let duration =
          (this.player.allTimestamps[this.player.allTimestamps.length - 1] -
            this.player.allTimestamps[0]) /
          1000;
        let remainingDuration =
          this.options.plugins.record.maxLength - duration;

        this.remainingDuration = this.formatTime(Math.round(remainingDuration));
        if (
          this.player.recordedData.length > 0 &&
          this.isChromeIOS &&
          this.isRecordingVideo
        ) {
          this.recordedData = this.player.recordedData;
        }
      });

      // error handling
      this.player.on("error", (element, error) => {
        console.warn(error);
      });

      this.player.on("deviceError", () => {
        console.error("device error:", this.player.deviceErrorCode);
      });
    },
  },
  mounted() {
    this.isChromeIOS = navigator.userAgent.match("CriOS");
    if (!this.player) {
      this.computeRecorderSize();
      window.addEventListener("resize", (e) => {
        this.computeRecorderSize();
      });
      DB.collection("events")
        .doc(this.eventId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.$router.push("/");
            return;
          }
          this.remainingDuration = this.formatTime(doc.data().mediaduration);
          this.options.plugins.record.maxLength = doc.data().mediaduration;
          this.eventName = doc.data().name;
          this.eventHost = doc.data().host;
          this.setUpPlayer();
        });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style src="./index.css">

</style>